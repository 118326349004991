<template>
  <div>
    <sm-editable-item
      v-model="form"
      :validatePermission="false"
      :disabledAllFields="true"
      :isLoading="isLoadingPage"
      :breadcrumbs="breadcrumbs"
      :pageHeader="pageHeader"
      :fields="tableFields"
      @cancel="onCancel"
    >
      <template #tab-top-panel-0>
        <sm-editable-form
          v-model="form"
          :fields="formFields[0].form"
          :disabledAllFields="true"
        />
      </template>

      <template v-slot:table-logs-logFile="{ row }">
        <router-link
          class="editable-item__link"
          to=""
          @click.native="onShowLogFiles(row.id)"
        >
          Просмотреть
        </router-link>
      </template>
    </sm-editable-item>

    <sm-modal
      :show="showLogModal"
      class="modal-log"
      modalTitle="Содержимое файла лога"
      @close="onCloseLogModal"
    >
      <template #body>
        <div class="modal-log__inner-wrapper">
          <code class="modal-log__info">{{ log.data }}</code>
        </div>
      </template>
    </sm-modal>
  </div>
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('deploy');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmModal from '@/components/common/modals/SmModal.vue';
import SmEditableForm from '@/components/common/forms/SmEditableForm.vue';

export default {
  name: 'ReleasePublicationInfo',

  components: {
    SmEditableItem,
    SmModal,
    SmEditableForm,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      refreshPageInterval: null,
      form: {},
      showLogModal: false,
    };
  },

  computed: {
    ...mapState(['releasePublicationInfo', 'log']),

    pageHeader() {
      return `Информации о публикации релиза ${this.$route.params.id}`;
    },

    breadcrumbs() {
      return [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Проекты',
          route: { name: 'Deploy' },
        },
        {
          text: `${this.form.projectName}`,
          route: {
            name: 'DeployDetail',
            params: { id: this.$route.params.deployId },
          },
        },
        {
          text: `Информации по релизу ${this.$route.params.releaseId}`,
          route: {
            name: 'ReleaseInfo',
            params: {
              id: this.$route.params.releaseId,
              deployId: this.$route.params.deployId,
            },
          },
        },
        {
          text: this.pageHeader,
        },
      ];
    },

    formFields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'projectName',
              label: 'Название проекта',
            },
            {
              type: 'text',
              key: 'publicationName',
              label: 'Название публикации',
            },
            {
              type: 'text',
              key: 'releaseCreated',
              label: 'Дата создания релиза',
            },
            {
              type: 'text',
              key: 'status',
              label: 'Статус развертывания',
            },
            {
              type: 'text',
              key: 'statusMoment',
              label: 'Дата изменения статуса',
            },
          ],
        },
      ];
    },

    tableFields() {
      return [
        {
          table: {
            caption: 'Файлы логов развертывания',
            headers: [
              {
                text: '№',
                alias: 'id',
              },
              {
                text: 'Название файла',
                alias: 'name',
              },
              {
                text: 'Содержимое файла лога',
                alias: 'logFile',
              },
            ],
            items: this.form.logs || [],
            key: 'logs',
          },
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    this.fetchReleasePublicationInfo().finally(() => {
      this.isLoadingPage = false;
    });
    this.refreshPageInterval = setInterval(
      this.fetchReleasePublicationInfo,
      10000
    );
  },

  beforeDestroy() {
    clearInterval(this.refreshPageInterval);
  },

  methods: {
    ...mapActions(['getReleasePublicationInfo', 'getLog']),

    fetchReleasePublicationInfo() {
      return this.getReleasePublicationInfo({
        releaseId: this.$route.params.releaseId,
        publicationId: this.id,
      }).then(() => {
        this.form = this.lodash.cloneDeep(this.releasePublicationInfo.data);
      });
    },

    onShowLogFiles(id) {
      this.showLogModal = true;
      this.getLog({ logId: id });
    },

    onCloseLogModal() {
      this.showLogModal = false;
    },

    onCancel() {
      this.$router.push({
        name: 'ReleaseInfo',
        params: {
          id: this.$route.params.releaseId,
          deployId: this.$route.params.deployId,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.editable-item__link {
  text-decoration: underline;
  color: var(--blue);

  cursor: pointer;
}

.modal-log .modal {
  max-width: 95%;
}

.modal-log__inner-wrapper {
  overflow-y: auto;
  max-height: 500px;
}

.modal-log__info {
  white-space: pre;
}
</style>
